import React from 'react'
import SideBar from 'react-sidebar'
// import { Motion, spring, presets } from 'react-motion'
import Menu from './Menu'

class SideBarMenu extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      sidebarOpen: false,
      sidebarpullRight: true,
      toggle: false,
    }

    this.onSetSidebarOpen = this.onSetSidebarOpen.bind(this)
  }
  onSetSidebarOpen(state) {
    this.setState({ sidebarOpen: state.open, toggle: !this.state.toggle })
  }
  toggleMenu() {
    this.setState({
      sidebarOpen: !this.state.sidebarOpen,
      toggle: !this.state.toggle,
    })
  }
  render() {
    // const style = {
    //   overflow: 'visible',
    //   cursor: 'pointer',

    //   WebkitTapHighlightColor: 'rgba(0,0,0,0)',
    // }
    return (
      <SideBar
        sidebar={
          <Menu /> // Menu list
        }
        open={this.state.sidebarOpen}
        onSetOpen={this.onSetSidebarOpen}
        pullRight={this.state.sidebarpullRight}
        styles={{
          sidebar: {
            background: '#ffffff',
            width: '300px',
            position: 'fixed',
            zIndex: '3',
          },
          root: { position: 'relative', overflow: 'visible' },
          overlay: { backgroundColor: 'rgba(0, 0, 0, 0.65)', zIndex: 2 },
          content: {
            position: 'relative',
            overflow: 'visible',
            cursor: 'pointer',
          },
        }}
      >
        <button
          className="btn btn-nav"
          onClick={e => this.toggleMenu(e)}
          onKeyDown={e => this.handleKeyDown(e)}
        >
          <i className="fa fa-navicon" style={{ fontSize: '24px' }}></i>
        </button>
      </SideBar>
    )
  }
}

export default SideBarMenu
