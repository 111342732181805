import React from 'react'
import { Link } from 'gatsby'
import logoWhite from '../assets/images/logo-white.png'
import fb from '../assets/images/fb.svg'
import twitter from '../assets/images/twitter.svg'
import linkedin from '../assets/images/linkedin.svg'
import policy from '../assets/images/policy.svg'
import services from '../assets/images/services.svg'
import info from '../assets/images/info.svg'
import SampleForm from './Form'

const Footer = () => (
  <footer>
    <div className="container-fluid footer">
      {/* <a href="#top" title="Back to top">
        <div className="back-to-top">
          <i className="fas fa-chevron-up" />
          <span className="d-none">back to top</span>
        </div>
      </a> */}
      <div>
        <div className="row">
          <div className="container-fluid p-0">
            <div className="col-md-12 text-white sub-footer">
              <div className="row justify-content-md-center">
                <div className="col-lg-4 col-xl-4 col-md-6 col-12 text-center">
                  <Link to="/">
                    <img className="mt-3" src={logoWhite} alt="JJ Gates" />
                  </Link>

                  <div className="address-box mt-4">
                    <p>
                      12308 Rendon Rd Ste. 108 <br /> Burleson, Tx. 76028
                    </p>
                    <p>
                      Phone:{' '}
                      <a
                        className="font-weight-bold"
                        href="tel:+1 817-466-2794"
                      >
                        {' '}
                        (817) 466-2794
                      </a>
                    </p>
                  </div>
                  <h4 className="mt-2">Follow Us On:</h4>
                  <div className="col-md-4 p-0 mx-auto social-icons">
                    <a
                      href="https://www.facebook.com/JJAutomaticGateServiceDesign/"
                      target="_blank"
                      rel="noopener noreferrer nofollow"
                    >
                      <img className="footer-icons" src={fb} alt="facebook" />
                    </a>
                    <a
                      href="https://twitter.com/jjgatesinc"
                      target="_blank"
                      rel="noopener noreferrer nofollow"
                    >
                      <img
                        className="footer-icons"
                        src={twitter}
                        alt="twitter"
                      />
                    </a>
                    <a
                      href="https://www.linkedin.com/company/jj-gate-service"
                      target="_blank"
                      rel="noopener noreferrer nofollow"
                    >
                      <img
                        className="footer-icons"
                        src={linkedin}
                        alt="linkedin"
                      />
                    </a>
                  </div>
                </div>
                <div className="col-lg-4 col-xl-2 col-md-6 col-12 mt-5 border-right-2">
                  <h5 className="mt-3">
                    <img className="footer-img" src={policy} alt="policy" />
                    Terms &amp; Privacy
                  </h5>
                  <hr />
                  <ul>
                    <li>
                      <Link to="/terms-of-service">Terms of Service</Link>
                    </li>
                    <li>
                      <Link to="/privacy-policy">Privacy Policy</Link>
                    </li>
                  </ul>
                  <h5 className="mt-5">
                    <img className="footer-img" src={info} alt="Info" />
                    Company Info
                  </h5>
                  <hr />
                  <ul>
                    <li>
                      <Link to="/">
                        J&amp;J Automatic Gate Service &amp; Design
                      </Link>
                    </li>
                    <li>
                      <Link to="/about-us">About Us</Link>
                    </li>
                    <li>
                      <Link to="/contact-us">Contact</Link>
                    </li>
                  </ul>
                </div>
                <div className="col-lg-4 col-xl-2 col-md-6 col-12 mt-5">
                  <h5 className="mt-3">
                    <img className="footer-img" src={services} alt="Services" />
                    List of Services
                  </h5>
                  <hr />
                  <ul>
                    <li>
                      <Link to="/gates/services">Gate Services</Link>
                    </li>
                    <li>
                      <Link to="/gates">Custom Gates</Link>
                    </li>
                    <li>
                      <Link to="/gates/gate-types/automatic-gates">
                        Automatic Gates
                      </Link>
                    </li>
                    <li>
                      <Link to="/gates/gate-types/security-gates">
                        Security Gates
                      </Link>
                    </li>
                    <li>
                      <Link to="/gates/gate-types/driveway-gates">
                        Driveway Gates
                      </Link>
                    </li>
                    <li>
                      <Link to="/gates/gate-types/electric-gates">
                        Electric Gates
                      </Link>
                    </li>
                    <li>
                      <Link to="/gates/gate-types/sliding-gates">
                        Sliding Gates
                      </Link>
                    </li>
                    <li>
                      <Link to="/gates/gate-types/chain-link-gates">
                        Chain Link Gates
                      </Link>
                    </li>
                    <li>
                      <Link to="/gates/gate-types/wooden-gates">
                        Wooden Gates
                      </Link>
                    </li>
                    <li>
                      <Link to="/gates/gate-types/wrought-iron-gates">
                        Wrought Iron Gates
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="col-lg-6 col-xl-4 col-md-6 col-12 mt-5 form-section">
                  <SampleForm
                    form_name="Newsletter Form"
                    form_id="5df9d6e40dd8987a299e9099"
                    form_classname="form-newsletter update-footer"
                    data-thankyou-url="thank-you"
                  >
                    <div className="contact-header">
                      <h2 className="text-center text-dark">Get a Quote Now</h2>
                    </div>

                    <div className="form-row">
                      <div className="col-12">
                        <div className="form-group">
                          <input
                            className="form-control"
                            type="email"
                            name="Email Address"
                            id="email_address"
                          />
                          <label htmlFor="email_address">Email Address</label>
                        </div>
                        <small className="text-dark form-text mb-3">
                          We will never share your email with anyone else.
                        </small>
                      </div>
                      <div className="col-12">
                        <div className="form-group">
                          <input
                            className="form-control"
                            type="text"
                            name="Full Name"
                            id="full_name"
                          />
                          <label htmlFor="full_name">Fullname</label>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-group">
                          <textarea
                            className="form-control"
                            type="text"
                            name="Message"
                            id="message"
                          />
                          <label htmlFor="message">Message</label>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="webriq-recaptcha" />
                      </div>
                      <div className="col-12">
                        <div className="form-group">
                          <button
                            className="btn-global d-block text-center"
                            type="submit"
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  </SampleForm>
                </div>
              </div>
            </div>
          </div>
          <div className="container-fluid copyright text-center text-white">
            <div className="container">
              <div className="row">
                <div className="col-md-6 text-center text-md-left">
                  <p className="mb-0">
                    © {new Date().getFullYear()} J&amp;J Automatic Gate Service
                    &amp; Design. All Rights Reserved.
                  </p>
                </div>
                <div className="col-md-6 text-center text-md-right">
                  <p className="mb-0">
                    Designed And Powered By&nbsp;
                    <a
                      href="https://www.webriq.com/"
                      target="_blank"
                      rel="noopener noreferrer nofollow"
                    >
                      <span>WebriQ</span>
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
)

export default Footer
