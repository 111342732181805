import React from 'react'
import { Link } from 'gatsby'
import Sticky from 'react-sticky-el'
import SideBarMenu from './Sidebar'
import styl from './header.module.css'
import logo from '../assets/images/logo.png'
import Helmet from 'react-helmet'
import Offer from '../assets/images/financing.png'

const Header = () => (
  <header>
    <Sticky
      className={styl.stickyWrapper}
      stickyClassName={styl.isSticky}
      stickyStyle={{ transform: 'unset', zIndex: '2 !important' }}
    >
      <div className={` ${styl.menuContainer}`}>
        <div className="container header-wrap">
          <div className="row align-items-center">
            <div className="col-sm-5 col-md-3">
              <Link className="text-white" to="/">
                <img className="brand-logo mb-2" src={logo} alt="JJ Gates" />
              </Link>
            </div>
            <div className="col-md-4 text-right finance-wrap">
              <img
                className="mb-2"
                src={Offer}
                alt="Financing"
                style={{ width: '200px' }}
              />
            </div>
            <div className="col-sm-5 col-md-4 nav-cta text-right">
              <a className="btn-global" href="tel:8174662794">
                <i className="fas fa-mobile-alt" /> (817) 466-2794
              </a>

              <Helmet>
                <script async src="//248190.tctm.co/t.js" />
              </Helmet>
            </div>
            <div className="col-sm-2 col-md-1 burger-wrap text-right">
              <SideBarMenu />
            </div>
          </div>
        </div>
      </div>
    </Sticky>
  </header>
)

export default Header
