import React from 'react'
import { Link } from 'gatsby'
import { Nav } from 'react-bootstrap'
import Collapsible from 'react-collapsible'
import 'font-awesome/css/font-awesome.min.css'
import logo from '../assets/images/logo.png'
import fb from '../assets/images/fb.svg'
import twitter from '../assets/images/twitter.svg'
import linkedin from '../assets/images/linkedin.svg'

import './menu.css'
export default () => (
  <div className="navigation">
    <Nav className="flex-column">
      <div className="logo-site mx-auto mb-5">
        <img className="brand-logo mt-0" src={logo} alt="JJ Gates" />
      </div>
      <ul className="navlist text-left">
        <li>
          <Link to="/">Home</Link>
        </li>
        <li className="bg-1 text-left">
          <Collapsible trigger={<Link to="/gates">Gates</Link>}>
            <ul className="submenu">
              <li>
                <Link to="/gates/services">Gate Services</Link>
              </li>
              <li>
                <Link to="/gates/gate-types">Gate Types</Link>
              </li>
              <li>
                <Link to="/gates/gate-brands">Brands</Link>
              </li>
              <li>
                <Collapsible
                  trigger={<Link to="/gates/gate-designs">Designs</Link>}
                >
                  <ul className="submenu-sub">
                    <li>
                      <Link to="/gates/gate-designs/bell-gates">Bell Gate</Link>
                    </li>
                    <li>
                      <Link to="/gates/gate-designs/arched-gates">
                        Arched Gate
                      </Link>
                    </li>

                    <li>
                      <Link to="/gates/gate-designs/flat-top-gates">
                        Flat Top Gate
                      </Link>
                    </li>
                    <li>
                      <Link to="/gates/gate-designs/custom-gate-designs">
                        Custom Gate
                      </Link>
                    </li>
                    <li>
                      <Link to="/gates/gate-designs/cad-designs/">
                        CAD Designs
                      </Link>
                    </li>
                  </ul>
                </Collapsible>
              </li>
            </ul>
          </Collapsible>
        </li>
        <li className="text-left">
          <Collapsible trigger={<Link to="/locations">Locations</Link>}>
            <ul className="submenu">
              <li>
                <Link to="/locations/dallas-tx">Dallas, TX</Link>
              </li>
              <li>
                <Link to="/locations/fort-worth-tx">Fort Worth, TX</Link>
              </li>
              <li>
                <Link to="/locations/arlington-tx">Arlington, TX</Link>
              </li>
              <li>
                <Link to="/locations/burleson-tx" className="menu-gate">
                  Burleson, TX
                </Link>
              </li>
            </ul>
          </Collapsible>
        </li>
        <li>
          <Collapsible trigger={<Link to="/service-area">Service Areas</Link>}>
            <ul className="submenu">
              <li>
                <Collapsible
                  trigger={<Link to="/service-area/texas">Texas</Link>}
                >
                  <ul className="submenu-sub">
                    <li>
                      <Link
                        to="/service-area/texas/collin-county"
                        className="menu-gate"
                      >
                        Collin County, TX
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/service-area/texas/dallas-county"
                        className="menu-gate"
                      >
                        Dallas County, TX
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/service-area/texas/denton-county"
                        className="menu-gate"
                      >
                        Denton County, TX
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/service-area/texas/ellis-county"
                        className="menu-gate"
                      >
                        Ellis County, TX
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/service-area/texas/hood-county"
                        className="menu-gate"
                      >
                        Hood County, TX
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/service-area/texas/johnson-county"
                        className="menu-gate"
                      >
                        Johnson County, TX
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/service-area/texas/parker-county"
                        className="menu-gate"
                      >
                        Parker County, TX
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/service-area/texas/tarrant-county"
                        className="menu-gate"
                      >
                        Tarrant County, TX
                      </Link>
                    </li>
                  </ul>
                </Collapsible>
              </li>
            </ul>
          </Collapsible>
        </li>
        <li>
          <Link to="/gallery">Gallery</Link>
        </li>
        <li>
          <Link to="/blog">Blog</Link>
        </li>
        <li>
          <Link to="/about-us">About Us</Link>
        </li>
        <li>
          <Link to="/contact-us">Contact Us</Link>
        </li>
      </ul>
      <h5 className="mt-2 text-uppercase primary-color sidebar-follow text-center">
        Follow Us On:
      </h5>
      <div className="col-lg-6 p-0 social-icons text-center mx-auto">
        <div className="d-flex w-100 justify-content-center">
          <a
            href="https://www.facebook.com/JJAutomaticGateServiceDesign/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img className="footer-icons" src={fb} alt="facebook" />
          </a>
          <a
            href="https://twitter.com/jjgatesinc"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img className="footer-icons" src={twitter} alt="twitter" />
          </a>
          <a
            href="https://www.linkedin.com/company/jj-gate-service"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img className="footer-icons mr-0" src={linkedin} alt="linkedin" />
          </a>
        </div>
      </div>
    </Nav>
  </div>
)
