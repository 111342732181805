import React, { useEffect } from 'react'
import Header from './Header'
import Footer from './Footer'
import '../components/style.css'
import 'bootstrap/dist/css/bootstrap.css'
// import 'font-awesome/css/font-awesome.min.css'
import '../fonts/fontawesome-all.min.css'

function Layout({ children }) {
  useEffect(() => {
    const script = document.createElement('script')
    const script2 = document.createElement('script')

    script.src = 'assets/broadly.js'
    script.async = true

    script2.src = 'https://chat.broadly.com/javascript/chat.js'
    script2.async = true

    document.body.appendChild(script)
    document.body.appendChild(script2)

    return () => {
      document.body.removeChild(script)
      document.body.removeChild(script2)
    }
  }, [])
  return (
    <>
      <Header />
      <div>{children}</div>
      <Footer />
    </>
  )
}
export default Layout
